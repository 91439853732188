<template>
  <section
    ref="el"
    class="pointer-events-none relative z-20 overflow-hidden pb-28 md:p-0 lg:pt-12"
    :class="{
      'md:mt-[-5%]': data.overlap_top.value === 'small',
      'md:mb-[-3%]': data.overlap_bottom.value === 'small',
      'md:mt-[-10%]': data.overlap_top.value === 'medium',
      'md:mb-[-6%]': data.overlap_bottom.value === 'medium',
    }"
  >
    <div v-if="Array.isArray(data.images)" class="flex pr-4 md:pr-9">
      <!--  IMAGE LEFT BIG -->
      <div v-if="data.images.length > 0" class="order-1 w-[70%] md:w-1/2">
        <svg class="absolute">
          <defs>
            <clipPath id="clip-path-a" clipPathUnits="objectBoundingBox">
              <path
                d="M0.998,0.232 C0.985,0.182,0.939,0.136,0.876,0.112 C0.807,0.085,0.723,0.08,0.647,0.094 C0.562,0.109,0.504,0.156,0.423,0.171 C0.386,0.178,0.343,0.176,0.312,0.161 C0.247,0.128,0.242,0.053,0.171,0.02 C0.123,-0.002,0.085,-0.002,0.002,0.008 L0.002,0.781 L0.003,0.781 L0.003,1 C0.199,1,0.206,0.864,0.423,0.845 C0.64,0.827,0.704,0.818,0.759,0.763 C0.813,0.708,0.752,0.639,0.701,0.604 C0.66,0.576,0.599,0.553,0.592,0.514 C0.586,0.481,0.627,0.45,0.673,0.435 C0.719,0.421,0.771,0.418,0.821,0.41 C0.943,0.391,1,0.316,0.998,0.232"
              />
            </clipPath>
          </defs>
        </svg>

        <AppImage
          :src="data.images[0]"
          :aspect-ratio="2 / 3"
          class="clip-a pointer-events-auto w-full"
          loading="lazy"
        />
      </div>

      <!-- IMAGE CENTER ROUND -->
      <div
        v-if="data.images.length > 1"
        class="relative order-2 w-[16%] shrink-0"
      >
        <div
          class="absolute top-[90%] left-[-190%] w-[200%] md:left-[-25%] md:top-[45%] md:w-[150%]"
        >
          <svg class="h-0 w-0">
            <defs>
              <clipPath id="clip-path-b" clipPathUnits="objectBoundingBox">
                <path
                  d="M0.045,0.697 C0.002,0.595,-0.024,0.024,0.488,0.013 C1,0.002,1,0.358,1,0.459 C0.995,0.618,0.923,0.709,0.8,0.823 C0.711,0.905,0.582,0.987,0.489,1 C0.403,1,0.186,1,0.045,0.697"
                />
              </clipPath>
            </defs>
          </svg>

          <AppImage
            :src="data.images[1]"
            class="clip-b pointer-events-auto rounded-full"
            :aspect-ratio="1 / 1"
            loading="lazy"
          />
        </div>
      </div>

      <!-- IMAGE RIGHT SMALL -->
      <div v-if="data.images.length > 2" class="relative order-3 w-1/3">
        <svg class="h-0 w-0">
          <defs>
            <clipPath id="clip-path-c" clipPathUnits="objectBoundingBox">
              <path
                d="M0.357,0.595 C0.015,0.758,-0.056,0.184,0.368,0.063 C0.793,-0.058,1,0.032,1,0.279 C1,0.477,0.988,0.556,0.988,0.674 C0.988,0.793,1,0.951,0.909,0.991 C0.521,1,0.606,0.476,0.357,0.595"
              />
            </clipPath>
          </defs>
        </svg>
        <div
          class="absolute left-[-50%] top-[50%] w-[150%] md:inset-x-0 md:top-[17%] md:w-full"
        >
          <AppImage
            :src="data.images[2]"
            :aspect-ratio="1 / 1"
            class="clip-c pointer-events-auto"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <!-- BACKGROUND WHITE -->
    <div class="absolute inset-x-0 bg-white" />
  </section>
</template>

<script setup>
const props = defineProps({
  data: { type: Object, required: true },
})

const el = ref(null)

onMounted(() => {
  const { $gsap } = useNuxtApp()
  const mm = $gsap.matchMedia()
  const breakPoint = 800

  // wait for next tick
  $gsap.delayedCall(0.3, () => {
    mm.add(
      {
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
      },
      (context) => {
        let { isMobile } = context.conditions

        const tl = $gsap.timeline({
          scrollTrigger: {
            trigger: el.value,
            start: isMobile ? 'top 80%' : 'top 30%',
            end: isMobile ? 'bottom top' : 'bottom 60%',
            scrub: isMobile ? 1 : 5,
            markers: false,
          },
        })

        if (props.data.images.length >= 1) {
          tl.add(
            $gsap.to('#clip-path-a path', {
              attr: {
                d: 'M1,0.263 C1,0.21,0.982,0.139,0.915,0.114 C0.843,0.087,0.758,0.07,0.678,0.083 C0.59,0.098,0.538,0.101,0.476,0.101 C0.346,0.101,0.333,0.095,0.286,0.073 C0.246,0.055,0.251,0.055,0.177,0.023 C0.127,0.001,0.087,0,0,0.01 L0,0.783 L0.001,0.783 L0.001,1 C0.206,1,0.154,0.9,0.381,0.881 C0.608,0.863,0.753,0.845,0.81,0.79 C0.866,0.736,0.864,0.675,0.81,0.639 C0.767,0.611,0.739,0.6,0.729,0.579 C0.714,0.55,0.722,0.515,0.742,0.488 C0.763,0.459,0.809,0.442,0.857,0.427 C0.975,0.391,1,0.355,1,0.263',
              },
            })
          )
        }

        if (props.data.images.length >= 2) {
          tl.add(
            $gsap.to('#clip-path-b path', {
              attr: {
                d: 'M0.193,0.804 C0.141,0.676,-0.071,0.098,0.326,0.089 C0.724,0.081,1,0.222,1,0.404 C1,0.551,0.916,0.602,0.813,0.785 C0.71,0.967,0.642,1,0.529,1 C0.426,1,0.284,1,0.193,0.804',
              },
            }),
            0
          )
        }

        if (props.data.images.length >= 3) {
          tl.add(
            $gsap.to('#clip-path-c path', {
              attr: {
                d: 'M0.193,0.804 C0.141,0.676,-0.071,0.098,0.326,0.089 C0.724,0.081,1,0.222,1,0.404 C1,0.551,0.916,0.602,0.813,0.785 C0.71,0.967,0.642,1,0.529,1 C0.426,1,0.284,1,0.193,0.804',
              },
            }),
            0
          )
        }
      },
      el.value
    )
  })
})

onBeforeUnmount(() => {
  if (typeof ctx !== 'undefined' && ctx) {
    ctx.revert()
  }
})
</script>

<style lang="scss" scoped>
.clip-a {
  clip-path: url(#clip-path-a);
}

.clip-b {
  clip-path: url(#clip-path-b);
}
.clip-c {
  clip-path: url(#clip-path-c);
}
</style>
